<template>
<div>
  <van-nav-bar class="navbar" title="查看地址" fixed left-arrow  @click-left="leftReturn"/>
  <estateMap  class="baiduHouseMap">
            <div><span>楼盘纬度{{latitude}}</span>
            <span>楼盘经度{{longitude}}</span>
            <span>员工纬度{{personLat}}</span>
            <span>员工经度{{houseLng}}</span></div>
  </estateMap>
<!--  <baidu-map ref="baidumap" class="baiduHouseMap" :center="{lng: longitude, lat: latitude}" :zoom="18">-->
<!--    <bm-marker :position="{lng: longitude, lat: latitude}" :dragging= false :clicking= false animation="BMAP_ANIMATION_BOUNCE">-->
<!--      <bm-label :content="estateName" :labelStyle="{color: 'red', fontSize : '18px'}" :offset="{width: -35, height: 30}"/>-->
<!--    </bm-marker>-->
<!--  </baidu-map>-->
</div>
</template>

<script>
import { NavBar,Swipe, SwipeItem} from 'vant';
import estateMap from "@/components/rongxun/rx-map/estateMap";
import {getHouseAddress} from "../../../getData/getData";
import {getStaffId, responseUtil} from "../../../libs/rongxunUtil";
export default {
  name: "viewAddress",

    components:{
      [NavBar.name]:NavBar,
      [Swipe.name]:Swipe,
      [SwipeItem.name]:SwipeItem,
    [estateMap.name]:estateMap,
  },
  data() {
    return {
      // 弹层需要的参数
      setHouse_id: '',
      estateName:'',
      longitude:'',
      latitude:'',
      personLat:'',
      houseLng:'',
      mapInit: {
        // width:'450px',
        // height:'250px',
        center: { lng: '', lat: '' },
        zoom: 18,
        radius: 30,
        keyword: ''
      },
      // 弹层需要的参数
      houseMap: {
        title:'房源地图',
        buttonArray: [
          {title: '关闭', click: (e) => this.close(e)}
        ],
      },
    }
  },
  mounted: function () {
   
  },
  activated() {
    console.log(this.$route.query,"this.$route.query")
    this.estateName =  this.$route.query.estateName
    this.longitude =  this.$route.query.longitude
    this.latitude =  this.$route.query.latitude
    this.personLat=this.$route.query.latitude,
    this.houseLng=this.$route.query.longitude,
    this.createCircle()
  },

  watch:{
    // '$route'(to,from){
    //   this.estateName =  to.query.estateName
    //   this.longitude =  to.query.longitude
    //   this.latitude =  to.query.latitude
    //   this.personLat=to.query.latitude
    //       this.houseLng=to.query.longitude
    //   this.createCircle()
    // }
  },
  methods:{
    leftReturn(){
      this.$router.go(-1);
    },
    addMarker(staffPoint,map){
      var marker = new BMap.Marker(staffPoint);
      map.addOverlay(marker);
    },

    //创建圆形区域
    createCircle: function () {
      var that = this
      var map = new BMap.Map("container");
      //右上角缩小、放大按钮
      var top_right_navigation = new BMap.NavigationControl({anchor: BMAP_ANCHOR_TOP_RIGHT, type: BMAP_NAVIGATION_CONTROL_SMALL});
      //创建中心点
      var pt = new BMap.Point(that.longitude, that.latitude)
      map.centerAndZoom(pt, 19)
      var marker = new BMap.Marker(pt)
      // var circle = new BMap.Circle(pt, 30, {
      //   strokeColor: 'Red',
      //   strokeWeight: 1,
      //   strokeOpacity: 1,
      //   Color: 'Red',
      //   fillColor: '#f03',
      //   fillOpacity: 0.2
      // })// 创建圆形区域
      map.addOverlay(marker)
      // map.addOverlay(circle)
      map.addControl(top_right_navigation);
      //员工经纬度
      var lngSpan = 121.525704;
      var latSpan = 38.8578;
      // var staffPoint = new BMap.Point('121.6608', '38.92583');
      var staffPoint = new BMap.Point(that.longitude, that.latitude);
      that.addMarker(staffPoint,map);
    },

  }
}
</script>

<style scoped>
.baiduHouseMap{
  width: 100%;
  height: 100vh;
}

</style>
